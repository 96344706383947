import { Loader } from '@googlemaps/js-api-loader';
import $ from "jquery"

const googleMapsLoader = new Loader({
  apiKey: GOOGLE_MAPS_API_KEY,
  version: "weekly",
  libraries: ["places"]
});

var autocomplete;
function init_autocomplete(){
  googleMapsLoader.LANGUAGE = I18n.currentLocale();
  googleMapsLoader.load().then(() => {
    var defaultBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(82.16, 40.22),
      new google.maps.LatLng(27.49, -31.46)
    );

    var input = document.getElementsByClassName('autocomplete_places')[0];
    var options = {
      bounds: defaultBounds,
      types: ['(cities)']
    };
    autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.setFields(['address_component']);
    autocomplete.addListener('place_changed', fillInCity);
  });
}

function fillInCity(){
  var place = autocomplete.getPlace();
  let name = place.address_components[0] && place.address_components[0].long_name || ''
  document.getElementsByClassName('autocomplete_places')[0].value = name;
}

var substringMatcher = function(strs) {
  return function findMatches(q, cb) {
    var matches, substringRegex;
    matches = [];
    substrRegex = new RegExp(q, 'i');
    $.each(strs, function(i, str) {
      if (substrRegex.test(str)) {
        matches.push(str);
      }
    });
    cb(matches);
  };
};

document.addEventListener("turbolinks:load", () => {
  if($(".autocomplete_places").length > 0){
    init_autocomplete();
  }
})
